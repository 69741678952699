<template>
    <div class="page-postpay-mobile-services">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">PostPay Mobile Services</h1>
                </div>

                <div class="col-12">
                    <app-error v-model="errors.show" :message="errors.message" />

                    <app-table
                        class="postpay-mobile-services-table"

                        title="Recent Provisioning"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"

                        @remove-service="onRemoveService"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>

                <div class="col-12">
                    <app-adding-banner-service service="PostPay Mobile" :tablet-mode="true" />
                </div>
            </div>
        </div>

        <app-dialog-service-removal
            v-if="dialogs.service.remove.show"
            v-model="dialogs.service.remove.show"

            :uuid="dialogs.service.remove.uuid"

            @confirm="onDialogServiceRemovalConfirm"
            @close="onDialogServiceRemovalClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appAddingBannerService from '@/components/app-adding-banner-service'

import appDialogServiceRemoval from './components/app-dialog-service-removal'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appLoader,
        appError,
        appTable,
        appPagination,
        appAddingBannerService,

        appDialogServiceRemoval,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc', // asc, desc
                },
            },

            sort: [
                { field: 'State',  title: 'State'  },
                { field: 'IMSI',   title: 'IMSI'   },
                { field: 'MSISDN', title: 'MSISDN' },

                { field: 'CreatedAtNanos', title: 'Created at' },
                { field: 'UpdatedAtNanos', title: 'Updated at' },
            ],

            cols: [
                { key: 'uuid',   title: 'Service UUID', highlight: true, multiline: true },
                { key: 'IMSI',   title: 'IMSI',         sort: { field: 'IMSI'   }        },
                { key: 'MSISDN', title: 'MSISDN',       sort: { field: 'MSISDN' }        },
                { key: 'State',  title: 'State',        sort: { field: 'State'  }        },

                { key: 'CreatedAtNanos', title: 'Created at', sort: { field: 'CreatedAtNanos' } },
                { key: 'UpdatedAtNanos', title: 'Updated at', sort: { field: 'UpdatedAtNanos' } },

                { key: 'remove', action: 'remove-service', icon: 'remove', behavior: 'detached' },
            ],

            products: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                service: {
                    remove: {
                        show: false,
                        uuid: null,
                    },
                },
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getProducts()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getProducts()
        },

        onPageChange(page) {
            this.getProducts(page)
        },

        getProducts(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_postpay/FindPostpayProductsPaginated', params)
                .then(({ PostpayProducts, PageInfo }) => {
                    this.products = PostpayProducts

                    this.pagination.page = PageInfo.PageNumber
                    this.pagination.total = PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.products = []

                    this.loading = false
                })
        },

        onRemoveService(row) {
            this.dialogs.service.remove.uuid = row.uuid
            this.dialogs.service.remove.show = true
        },

        onDialogServiceRemovalConfirm() {
            this.getProducts()

            this.dialogs.service.remove.uuid = null
            this.dialogs.service.remove.show = false
        },

        onDialogServiceRemovalClose() {
            this.dialogs.service.remove.uuid = null
            this.dialogs.service.remove.show = false
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        rows() {
            const rows = Array.isArray(this.products)
                ? this.products.map(product => ({
                    uuid: product.UUID,
                    IMSI: product.IMSI ? product.IMSI : '?',
                    MSISDN: product.MSISDN ? product.MSISDN : '?',
                    State: product.State ? product.State : '?',

                    CreatedAtNanos: formatDateNano(product.CreatedAtNanos),
                    UpdatedAtNanos: formatDateNano(product.UpdatedAtNanos),

                    remove: {
                        disabled: product.State.toLowerCase() != 'active',
                    },
                }))
                : []

            return rows
        },
    },
}
</script>

<style lang="scss">
.page-postpay-mobile-services {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .postpay-mobile-services-table {
        @include table-cols-width((275px, 150px, 150px, 150px, 150px, 150px, 48px), true, 1);
    }

    .app-pagination,
    .app-adding-banner-service {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-postpay-mobile-services {
        .postpay-mobile-services-table {
            @include table-cols-width((275px, 150px, 150px, 150px, 120px, 120px, 48px), true, 1);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-postpay-mobile-services {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .postpay-mobile-services-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        .app-pagination,
        .app-adding-banner-service {
            margin-top: 15px;
        }
    }
}
</style>